// -----------------------------------------------------------------------------------------------------
// @ Fuse sidebar
// -----------------------------------------------------------------------------------------------------
@import "variables";

.fuse-sidebar[name="billing-right-sidebar"],
.fuse-sidebar[name="billing-quote-right-sidebar"]
  .fuse-sidebar[name="billing-orderVoucher-right-sidebar"] {
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
  overflow-y: auto;
  min-width: $fuse-sidebar-width;
  max-width: $fuse-sidebar-width;
  background-color: #fff !important;
  bottom: unset !important;
  width: $fuse-sidebar-width;
  z-index: 9999 !important;
  border-radius: 7px 0 0 7px !important;

  &.open {
    transform: unset !important;
  }

  .headerSide {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
  }

  .header-special {
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    color: black;
    margin: 0px 0 0 24px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .headerButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 15px;
  }
}

.sidebar-container {
  width: 100%;
  height: auto;
  margin-top: 49px;

  .content {
    width: 100%;
    height: 100%;
    background-color: white;
  }
}

::ng-deep.sidebar-container .mat-mdc-tab-label {
  padding: 0 4px !important;
  min-width: 121px !important;
  height: 37px !important;
  margin-left: unset !important;
}

.fuse-sidebar-header {
  display: flex;
  flex-direction: row;
  height: 50px !important;
  // border-bottom: 2px solid #272d4012 !important;
  padding: 10px !important;
  position: fixed;
  box-shadow: 0px 2px 4px #272d4029;
  width: $fuse-sidebar-width !important;
  z-index: 999;
  background-color: white;
  border-radius: 7px 0 0 0 !important;

  .fuse-sidebar-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    // .mat-icon {
    //   height: 20px !important;
    //   width: 20px !important;
    //   font-size: 20px !important;

    //   svg {
    //     width: 16px !important;
    //     height: 16px !important;
    //     position: relative !important;
    //     top: 1px !important;
    //   }
    // }

    // .mat-icon:hover {
    //   background-color: #e1e1e1;
    //   border-radius: 50%;
    // }

    .mat-icon {
      width: 25px !important;
      font-size: 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px !important;
        height: 16px !important;
        position: relative !important;
        top: 1px !important;
      }
    }

    .mat-icon:hover {
      background-color: #272d3e;
      border-radius: 50%;
      color: white;
    }

    .fuse-sidebar-header-Left {
      width: 25px;
      max-width: 25px;
      min-width: 25px;
    }

    .fuse-sidebar-header-right {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .status-docs,
      .status-docs-red {
        position: relative;
        height: 24px;
        max-width: 16px;
        min-width: 20px;
        overflow: hidden;
        border-radius: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        // font-weight: unset !important;
        bottom: 1px;
        padding-bottom: 7px;
        padding-left: 9px;
        letter-spacing: 4px;
        box-shadow: unset !important;

        .mdc-button__label {
          position: relative;
          right: 5px;
        }

        .mdc-button__label .status-text {
          display: none;
        }

        .mdc-button__label .status-icon {
          display: block;

          .mat-icon {
            position: relative;
            top: 4px;
            right: 3px;
            vertical-align: bottom !important;
          }

          .mat-icon:hover {
            background-color: unset !important;
            border-radius: unset !important;
          }
        }
      }

      .status-docs:hover {
        transition: all 0.1s;
        background-color: red;
        max-width: 150px !important;
        min-width: 110px !important;
        overflow: visible !important;
        justify-content: center !important;
        letter-spacing: unset !important;
        padding-left: 14px !important;
        padding-right: 0px;

        .status-text {
          display: block;

          .mat-icon {
            position: relative;
            top: 4px;
            vertical-align: bottom !important;
          }

          .mat-icon:hover {
            background-color: unset !important;
            border-radius: unset !important;
          }
        }

        .status-icon {
          display: none;
        }
      }

      .status-docs-red:hover {
        transition: all 0.1s;
        max-width: 180px !important;
        min-width: 140px !important;
        overflow: visible !important;
        justify-content: center !important;
        letter-spacing: unset !important;
        padding-left: 4px !important;
        padding-right: 0px;

        .status-text {
          display: block;

          .mat-icon {
            position: relative;
            top: 4px;
            vertical-align: bottom !important;
          }

          .mat-icon:hover {
            background-color: unset !important;
            border-radius: unset !important;
          }
        }

        .status-icon {
          display: none;
        }
      }

      .status-docs[aria-expanded*="true"] {
        transition: all 0.1s;
        max-width: 150px !important;
        min-width: 105px !important;
        overflow: visible !important;
        justify-content: center !important;
        letter-spacing: unset !important;
        padding-left: 16px !important;
        padding-right: 0px;

        .status-text {
          display: block;

          .mat-icon {
            position: relative;
            top: 4px;
            vertical-align: bottom !important;
          }

          .mat-icon:hover {
            background-color: unset !important;
            border-radius: unset !important;
          }
        }

        .status-icon {
          display: none;
        }
      }

      .status-docs-red[aria-expanded*="true"] {
        transition: all 0.1s;
        max-width: 180px !important;
        min-width: 137px !important;
        overflow: visible !important;
        justify-content: center !important;
        letter-spacing: unset !important;
        padding-left: 4px !important;
        padding-right: 0px;

        .status-text {
          display: block;

          .mat-icon {
            position: relative;
            top: 4px;
            vertical-align: bottom !important;
          }

          .mat-icon:hover {
            background-color: unset !important;
            border-radius: unset !important;
          }
        }

        .status-icon {
          display: none;
        }
      }

      .mat-mdc-raised-button.mat-button-disabled.status-docs {
        top: -1px !important;
      }

      .mat-mdc-raised-button.mat-button-disabled.status-docs-red {
        top: -1px !important;
      }
    }
  }
}

.fuse-sidebar-action-menu {
  min-height: 30px !important;

  .menu-item-style.mat-menu-item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 16px !important;
  }
}
