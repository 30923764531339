// -----------------------------------------------------------------------------------------------------
// @ Buttons
// -----------------------------------------------------------------------------------------------------
@import "variables";

.sirh-btn-primary {
  background-color: $buttons-primary-color !important;
  color: white !important;
}

.sirh-btn-primary-disabled {
  background-color: $buttons-primary-color-disabled !important;
  color: white !important;
}

.sirh-btn-cyan {
  background-color: $buttons-cyan-color !important;
  color: antiquewhite !important;
}

.sirh-btn-footer {
  position: relative;
  left: 50px;
  color: #fff !important;
  width: 180px !important;
  font-weight: 500;
  line-height: 15px;
  border: 1px solid rgb(241, 236, 236) !important;
  background-color: #303a52 !important;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
    5px 2px 15px rgba(215, 240, 248, 0.12) !important;
}

.sirh-btn-header {
  background-color: white !important;
  color: $buttons-primary-color !important;
  border: 1px solid #3e4962 !important;
}

.sirh-btn-header:hover {
  background-color: #324062 !important;
  color: white !important;
  border: 1px solid #ffffff38 !important;
}

.sirh-btn-header-disabled {
  background-color: #303a52 !important;
  color: white !important;
  border: 2px solid $buttons-primary-color-disabled !important;
  font-weight: 800;
}

.sirh-btn-danger {
  background-color: $buttons-danger-color !important;
  color: white !important;
}

.sirh-btn-success {
  background-color: $buttons-success-color !important;
  color: white !important;
}

.sirh-btn-dark {
  background-color: $buttons-dark-color !important;
  color: white !important;
}

.sirh-btn-dark-disabled {
  background-color: $buttons-dark-color-disabled !important;
  color: white !important;
}

.sirh-delete-all-btn {
  background-color: $buttons-white-color !important;
  color: #f44336 !important;
}

.sirh-btn-not-sale {
  background-color: $buttons-not-sale-color !important;
  color: rgba(0, 0, 0, 0.87);
}

.sirh-btn-sale {
  background-color: $buttons-sale-color !important;
  color: rgba(0, 0, 0, 0.87);
}

.sirh-btn-status {
  background-color: $buttons-green-color !important;
  color: #166534 !important;
  padding: 0 5px 0 6px !important;
  border-radius: 9999px !important;
  line-height: 24px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  height: 27px !important;
}

.sirh-btn-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed !important;
}

.sirh-btn-white {
  background-color: $buttons-white-color !important;
  color: black !important;
}

.sirh-btn-primary,
.sirh-btn-white,
.sirh-btn-danger,
.sirh-btn-closing,
.sirh-btn-success,
.sirh-btn-rapprochment,
.sirh-btn-dark,
.sirh-btn-dark-disabled,
.sirh-btn-primary-disabled,
.sirh-delete-all-btn,
.sirh-btn-disabled,
.sirh-btn-not-sale,
.sirh-btn-sale {
  padding: 1px 12px 0 7px !important;
  border-radius: 5px !important;
  line-height: 40px !important;
  box-shadow: none !important;
  font-size: 13px !important;
  height: 33px !important;

  mat-icon {
    height: 20px !important;
    width: 20px !important;
    font-size: 20px !important;
    position: relative !important;
    top: 1px !important;
  }
}

.sirh-btn-header {
  padding: 0 12px 2px 8px !important;
  border-radius: 5px !important;
  font-weight: 600;
  line-height: 40px !important;
  box-shadow: none !important;
  font-size: 13px !important;
  height: 32px !important;

  mat-icon {
    height: 20px !important;
    width: 20px !important;
    font-size: 20px !important;
    position: relative !important;
    top: 1px !important;
  }
}

.clear-input,
.clear-button,
.status-icon {
  & .mat-icon {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}

.mat-mdc-icon-button {
  overflow: hidden !important;

  .mat-mdc-button-ripple {
    border-radius: 0 !important;
    overflow: hidden !important;
  }
}

.mdc-button {
  .mdc-button__label {
    .mat-button-wrapper {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.setting-btn-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.doc-settings-btn-content {
  min-width: 250px;
  height: 40px;
  min-height: 40px !important;

  .test-font-size {
    font-size: 14px;
  }

  .mat-icon {
    font-size: 20px;

    svg {
      width: 20px;
      margin-left: 3px;
      height: 19px;
    }
  }
}
