// -----------------------------------------------------------------------------------------------------
// @ variables
// -----------------------------------------------------------------------------------------------------

// breakpoints
$xs-breakpoint: 576px;
$sm-breakpoint: 768px;
$md-breakpoint: 992px;
$lg-breakpoint: 1200px;

// example: header height
$header-height: 100px;

// buttons colors
$buttons-primary-color: #272d40;
$buttons-danger-color: #ef4b40;
$buttons-success-color: #63e178;
$buttons-dark-color: #272d40;
$buttons-white-color: #ffffff;
$buttons-black-color: #000;
$buttons-green-color: #bbf7d0;
$buttons-cyan-color: #2485bd;

// disabled buttons
$buttons-primary-color-disabled: #394b6fa1;
$buttons-dark-color-disabled: #3c42529e;

// snackbarks colors
$snackbark-error-color: #fb6666;
$snackbark-success-color: #7bbd44;
$snackbark-info-color: #039be5;
$snackbark-white-color: #ffffff;

//status colors
$buttons-sale-color: #44b14d;
$buttons-not-sale-color: #e79797;

// general colors
$accent-color: #272d40;
$primary-color: #272d40;
$secondary-color: #272d40;
$tertiary-color: #274472;

// fuse-sidebar
$fuse-sidebar-width: 750px;

// bubble color
$bubble-color: #ff5c51;

// status tags color
$validated-color: #1c743e;
$validated-color-bg: #bbf7d0;
$validated-color-bd: unset;
$draft-color: #646464;
$draft-color-bg: #f1f1f1;
$draft-color-bd: #24242429;
$transmitted-color: #a0400e;
$transmitted-color-bg: #fef3c7;
$transmitted-color-bd: #ffc70059;
$accepted-color: #1e60cb;
$accepted-color-bg: #dbeafe;
$accepted-color-bd: #009ef759;
$closed-color: #b31b1b;
$closed-color-bg: #fecaca;
$closed-color-bd: #ff717140;
$to-be-delivered-color: #a0400e;
$to-be-delivered-color-bg: #fef3c7;
$to-be-delivered-color-bd: #ffc70059;
$delivered-color: #1e60cb;
$delivered-color-bg: #dbeafe;
$delivered-color-bd: #009ef759;
$canceled-color: #b31b1b;
$canceled-color-bg: #fecaca;
$canceled-color-bd: #ff717140;
$sale-color: #b31b1b;
$sale-color-bg: #fecaca;
$sale-color-bd: #ff717140;
$not-sale-color: #b31b1b;
$not-sale-color-bg: #fecaca;
$not-sale-color-bd: #ff717140;
$normal-color-bg: #bdff7140;
$normal-color: rgba(0, 0, 0, 0.671);
$sav-color-bg: #71ffd75d;
$sav-color: rgba(0, 0, 0, 0.671);
$operational-color-bg: #46ff4956;
$operational-color: #000000;
$out-of-service-color-bg: #ff53537b;
$out-of-service-color: #000000;

$new-color-bg: #53ffb27b;
$new-color: #000000;

$refused-color-bg: #ff53537b;
$refused-color: #000000;

$accepted-color-bg: #2afa317b;
$accepted-color: #000000;

$safe-color-bg: #46ff4956;
$safe-color: #000000;
$out-of-date-color-bg: #ff53537b;
$out-of-date-color: #000000;
$close-to-date-color-bg: #ff8f537b;
$close-to-date-color: #000000;

// dashboard colors

$tva-background-color: #fee2e2;
$stamp-background-color: #e0e7ff;
$discount-background-color: #ccfbf1;
$tva-color: #ff4d4dbf;
$stamp-color: #3f7fe7d4;
$discount-color: #3dcb9dc9;
$total-clients-color: #609af9;
$total-quotes-color: #fb6666;
$total-deliveryNotes-color: #ffb944;
$total-invoices-color: #63e178;
$out-of-stock-color: #c54545;
$out-of-stock-border-color: #a661f32e;
$top-products-color: #51b0d5;
$top-products-border-color: #29c9c938;
$top-clients-color: #a661f3;
$top-clients-border-color: #be84ff70;
$total-bdc-provider-color: #a961e7;

//navbar-colors
$dashboard-color: #1f9cff;
$folder-color: #ac25c3;
$stock-color: #27c942;
$clients-color: #f54337;
$ventes-color: #ff9800;
$achats-color: #2485bd;
$Treasury-color: #009688;
$products-color: #ffcf00;
$statistics-color: #7e3300;
$production-color: #646464;
$operation-color: #00904b;
$sav-color: #00904b;
