@import "variables";
// -----------------------------------------------------------------------------------------------------
// @ responsive
// -----------------------------------------------------------------------------------------------------

// for large screens < 1200px
@media screen and (max-width: $lg-breakpoint) {
}

// for medium screens < 992px
@media screen and (max-width: $md-breakpoint) {
}

// for small screens < 768px
@media screen and (max-width: $sm-breakpoint) {
}

// for extra small screens < 576px
@media screen and (max-width: $xs-breakpoint) {
  // on mobiles billing-right-sidebar should take 100% width
  .fuse-sidebar[name="billing-right-sidebar"],
  .fuse-sidebar[name="billing-quote-right-sidebar"]
    .fuse-sidebar[name="billing-orderVoucher-right-sidebar"] {
    min-width: 100vw !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}
