// -----------------------------------------------------------------------------------------------------
// @ snackbars
// -----------------------------------------------------------------------------------------------------
@import 'variables';

.error-snackbar {
  color: #f5f5f5 !important;
  background-color: $snackbark-error-color !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;

  .mat-mdc-snack-bar-container {
    color: #f5f5f5 !important;
    background-color: $snackbark-error-color !important;
  }

  .divButtonError,
  .mat-mdc-button {
    background-color: $snackbark-error-color !important;
    color: #ffffff !important;
    border: solid 1px $snackbark-white-color !important;
    border-radius: 5px !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: center !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonSuccess {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: inherit !important;
}

.success-message-snackbar {
  color: #f5f5f5 !important;
  background-color: $snackbark-success-color !important;
  //background-color: #8ed455 !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;

  .mat-mdc-snack-bar-container {
    margin-top: 7px !important;
    color: $snackbark-white-color !important;
    // background-color: #8ed455 !important;
    background-color: $snackbark-success-color !important;
  }

  .divButtonSuccess,
  .mat-mdc-button {
    //  background-color: #85c253 !important;
    background-color: $snackbark-success-color !important;
    color: #ffffff !important;
    border: solid 1px $snackbark-white-color !important;
    border-radius: 5px !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: center !important;

    .mat-mdc-snack-bar-actions {
      .mat-mdc-snack-bar-action {
        height: 29px !important;
      }
    }
  }

  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mat-mdc-simple-snack-bar {
  .mat-mdc-snack-bar-actions {
    .mat-mdc-snack-bar-action {
      height: 29px !important;
    }
  }
}

.info-message-snackbar {
  color: $snackbark-white-color !important;
  background-color: $snackbark-info-color !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;

  .mat-mdc-snack-bar-container {
    color: $snackbark-white-color !important;
    background-color: $snackbark-info-color !important;
  }

  .divButtonSuccess,
  .mat-mdc-button {
    background-color: $snackbark-info-color !important;
    color: $snackbark-white-color !important;
    border: solid 1px $snackbark-white-color !important;
    border-radius: 5px !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: center !important;
  }

  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}