// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
* {
    // font-size: 14px;
    font-family: "Inter", "Open Sans", "Muli", "Cairo", "Helvetica Neue", "Arial", sans-serif;
}

html {
    font-size: 62.5%;
    font-family: "Inter", "Open Sans", "Muli", "Cairo", "Helvetica Neue", "Arial", sans-serif;
    line-height: 1.4 !important;
    letter-spacing: -0.1px !important;
}

body {
    font-size: 13px;
    font-family:"Inter", "Open Sans", "Muli", "Cairo", "Helvetica Neue", "Arial", sans-serif;
}