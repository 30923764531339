// -----------------------------------------------------------------------------------------------------
// @ status tags
// -----------------------------------------------------------------------------------------------------
@import "variables";

.opened-status {
  background-color: $validated-color-bg !important;
  color: $validated-color !important;
  // border: 1px solid $validated-color-bd !important;
}

.finished-status,
.validated-status {
  background-color: $validated-color-bg !important;
  color: $validated-color !important;
  // border: 1px solid $validated-color-bd !important;
}

.pending-status,
.draft-status {
  background-color: $draft-color-bg !important;
  color: $draft-color !important;
  // border: 1px solid $draft-color-bd !important;
}

.new-status {
  background-color: $new-color-bg !important;
  color: $new-color !important;
  // border: 1px solid $draft-color-bd !important;
}

.accepted-status,
.signed-status {
  background-color: $accepted-color-bg !important;
  color: $accepted-color !important;
  // border: 1px solid $draft-color-bd !important;
}

.refused-status {
  background-color: $refused-color-bg !important;
  color: $refused-color !important;
  // border: 1px solid $draft-color-bd !important;
}

.in-progress-status,
.transmitted-status {
  background-color: $transmitted-color-bg !important;
  color: $transmitted-color !important;
  // border: 1px solid $transmitted-color-bd !important;
}

.to-be-delivered-status {
  background-color: $to-be-delivered-color-bg !important;
  color: $to-be-delivered-color !important;
  // border: 1px solid $to-be-delivered-color-bd !important;
}

.delivered-status {
  background-color: $delivered-color-bg !important;
  color: $delivered-color !important;
  // border: 1px solid $delivered-color-bd !important;
}

.canceled-status {
  background-color: $canceled-color-bg !important;
  color: $canceled-color !important;
  // border: 1px solid $canceled-color-bd !important;
}

.sale-status {
  background-color: $validated-color-bg !important;
  color: $validated-color !important;
  // border: 1px solid $validated-color-bd !important;
}

.true-status {
  background-color: $validated-color-bg !important;
  color: $validated-color !important;
  // border: 1px solid $validated-color-bd !important;
}

.false-status {
  background-color: $not-sale-color-bg !important;
  color: $not-sale-color !important;
  // border: 1px solid $not-sale-color-bd !important;
}

.not-sale-status {
  background-color: $not-sale-color-bg !important;
  color: $not-sale-color !important;
  // border: 1px solid $not-sale-color-bd !important;
}

.accepted-status {
  background-color: $accepted-color-bg !important;
  color: $accepted-color !important;
  // border: 1px solid $accepted-color-bd !important;
}

.closed-status {
  background-color: $closed-color-bg !important;
  color: $closed-color !important;
  // border: 1px solid $closed-color-bd !important;
}

.planned-status {
  background-color: $draft-color-bg !important;
  color: $draft-color !important;
  // border: 1px solid $draft-color-bd !important;
}

// TODO
.sales-status {
  background-color: $normal-color-bg !important;
  color: $normal-color !important;
  // border: 1px solid $draft-color-bd !important;
}

// TODO
.sav-status {
  background-color: $sav-color-bg !important;
  color: $sav-color !important;
}

// border: 1px solid $draft-color-bd !important;
.operational-status {
  background-color: $operational-color-bg !important;
  color: $operational-color !important;
}

.out-of-service-status {
  background-color: $out-of-service-color-bg !important;
  color: $out-of-service-color !important;
}

.safe-status {
  background-color: $safe-color-bg !important;
  color: $safe-color !important;
}

.close-to-date-status,
.suspended-status {
  background-color: $close-to-date-color-bg !important;
  color: $close-to-date-color !important;
}

.out-of-date-status {
  background-color: $out-of-date-color-bg !important;
  color: $out-of-date-color !important;
}

// dashboard colors
.stat-tva {
  background-color: $tva-background-color !important;
}

.stat-tva-title {
  color: $tva-color !important;
}

.stat-stamp {
  background-color: $stamp-background-color !important;
}

.stat-stamp-title {
  color: $stamp-color !important;
}

.stat-discount {
  background-color: $discount-background-color !important;
}

.stat-discount-title {
  color: $discount-color !important;
}

.stat-clients {
  color: $accepted-color !important;
}

.stat-clients-2 {
  color: $total-clients-color !important;
}

.stat-clients-2-footer {
  background-color: $total-clients-color !important;
}

.stat-clients-2-front:hover {
  border-bottom: 4px solid $total-clients-color !important;
}

.stat-quotes {
  color: $total-quotes-color !important;
}

.stat-quotes-footer {
  background-color: $total-quotes-color !important;
}

.stat-quotes-front:hover {
  border-bottom: 4px solid $total-quotes-color !important;
}

.stat-delivery-notes {
  color: $total-deliveryNotes-color !important;
}

.stat-delivery-notes-footer {
  background-color: $total-deliveryNotes-color !important;
}

.stat-delivery-notes-front:hover {
  border-bottom: 4px solid $total-deliveryNotes-color !important;
}

.stat-invoices {
  color: $total-invoices-color !important;
}

.stat-invoices-footer {
  background-color: $total-invoices-color !important;
}

.stat-invoices-front:hover {
  border-bottom: 4px solid $total-invoices-color !important;
}

.stat-order-vouchers {
  color: $total-bdc-provider-color !important;
}

.stat-order-vouchers-footer {
  background-color: $total-bdc-provider-color !important;
}

.stat-order-vouchers-front:hover {
  border-bottom: 4px solid $total-bdc-provider-color !important;
}

.stock-row {
  border-color: $out-of-stock-border-color !important;

  .row-c1-content {
    color: $out-of-stock-color;
  }
}

.stock-row:hover,
.top-stock {
  background-color: $out-of-stock-color;
}

.product-row {
  border-color: $top-products-border-color !important;

  .row-c1-content {
    color: $top-products-color;
  }
}

.product-row:hover,
.top-products {
  background-color: $top-products-color;
}

.client-row {
  border-color: $top-clients-border-color !important;

  .row-c1-content {
    color: $top-clients-color;
  }
}

.client-row:hover,
.top-clients {
  background-color: $top-clients-color;
}

// navbar classes

.dashboard-border-color {
  border-bottom: 4px solid $dashboard-color !important;
}

.dashboard-filter-border-color {
  border-left-color: $dashboard-color !important;
}

.folder-border-color {
  border-bottom: 4px solid $folder-color !important;
}

.folder-filter-border-color {
  border-left-color: $folder-color !important;
}

.stock-border-color {
  border-bottom: 4px solid $stock-color !important;
}

.stock-filter-border-color {
  border-left-color: $stock-color !important;

  mat-icon {
    stroke: $stock-color !important;
  }
}

.clients-border-color {
  border-bottom: 4px solid $clients-color !important;
}

.clients-filter-border-color {
  border-left-color: $clients-color !important;

  mat-icon {
    stroke: $clients-color !important;
  }
}

.ventes-border-color {
  border-bottom: 4px solid $ventes-color !important;
}

.ventes-filter-border-color {
  border-left-color: $ventes-color !important;

  mat-icon {
    stroke: $ventes-color !important;
  }
}

.achats-border-color {
  border-bottom: 4px solid $achats-color !important;
}

.achats-filter-border-color {
  border-left-color: $achats-color !important;

  mat-icon {
    stroke: $achats-color !important;
  }
}

.Treasury-border-color {
  border-bottom: 4px solid $Treasury-color !important;
}

.Treasury-filter-border-color {
  border-left-color: $Treasury-color !important;

  mat-icon {
    stroke: $Treasury-color !important;
  }
}

.products-filter-border-color {
  border-left-color: $products-color !important;

  mat-icon {
    stroke: $products-color !important;
  }
}

.operations-border-color {
  border-bottom: 4px solid $operation-color !important;
}

.operations-filter-border-color {
  border-left-color: $operation-color !important;

  mat-icon {
    stroke: $operation-color !important;
  }
}

.sav-border-color {
  border-bottom: 4px solid $sav-color !important;
}

.sav-filter-border-color {
  border-left-color: $sav-color !important;

  mat-icon {
    stroke: $sav-color !important;
  }
}

.products-border-color {
  border-bottom: 4px solid $products-color !important;
}

.statistics-border-color {
  border-bottom: 4px solid $statistics-color !important;
}

.production-border-color {
  border-bottom: 4px solid $production-color !important;
}

.new-status-border-color {
  border-left: 4px solid $draft-color !important;
}

.in-progress-status-border-color {
  border-left: 4px solid $ventes-color !important;
}

.complete-status-border-color {
  border-left: 4px solid $stock-color !important;
}
